import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { InclusionAuthGuard } from './core/guards/inclusionAuth.guard';
import { LayoutComponent } from './layouts/layout.component';

const routes: Routes = [
  { path: 'pages',
  component: LayoutComponent,
  loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  canActivate: [AuthGuard]
  },
  { path: 'ona',
  component: LayoutComponent,
  loadChildren: () => import('./reports/ona/ona.module').then(m => m.OnaModule),
  canActivate: [AuthGuard]
  },
  { path: 'inclusion',
  component: LayoutComponent,
  loadChildren: () => import('./reports/inclusion-award/inclusionaward.module').then(m => m.InclusionAwardModule),
  canActivate: [InclusionAuthGuard]
  },
  { path: '',
  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
