import { Component, OnInit, Input, Output} from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],

})
export class LeftsidebarComponent implements OnInit {
  status: boolean = false;
  @Input() sidebarType: string;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  
  openMobileMenu: boolean;


  ngOnInit() {
    this.openMobileMenu = false;
  }


  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  ngAfterViewInit() {
    const topnav = document.querySelector('body');
    topnav.removeAttribute('data-layout');

    document.body.classList.remove('authentication-bg');
    document.body.classList.remove('authentication-bg-pattern');

    // if (!this.isMobile()) {
    //   document.body.classList.add('sidebar-enable');
    // }

    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (window.innerWidth >= 768 && window.innerWidth <= 1599) {
      document.body.classList.add('left-side-menu-condensed');
    }


  }
  onResize(event) { 
    if (window.innerWidth >= 768 && window.innerWidth <= 1599) {
      document.body.classList.add('left-side-menu-condensed');
    }
    else if(window.innerWidth >= 1600){
      document.body.classList.remove('left-side-menu-condensed');
    }
}

  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }



  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.onToggleMobileMenu();
    this.status = !this.status;

  }


  onToggleMobileMenu() {

    if (document.body.classList.contains('sidebar-enable')) {
      document.body.classList.remove('sidebar-enable');
      document.body.classList.add('left-side-menu-condensed');
    } else {
      if (document.body.classList.contains('left-side-menu-condensed')) {
        document.body.classList.remove('left-side-menu-condensed');
      }
      document.body.classList.add('sidebar-enable');
    }

    if (!this.isMobile()) {
      document.body.classList.toggle('enlarged');
    }
   
  }
  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: '/' } });
  }
}
