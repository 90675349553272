import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

  selectedLang = new BehaviorSubject('en');

  constructor(
    private http: HttpClient
  ){}

  getMenu(lang:any){
    return this.http.get('assets/mocks/menu/menu_'+lang+'.json')
  }
  getAwardMenu(lang:any){
    return this.http.get('assets/mocks/menu/award_'+lang+'.json')
  }

}
