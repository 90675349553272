import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class InclusionAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser() as any;
        // console.log(currentUser)
        if (currentUser?.report_type === "inclusion_report") {
            return true;
        }else{
          this.router.navigate(['/login'],{fragment: localStorage.getItem('loginUrl')});
          return false;
        }

        // not logged in so redirect to login page with the return url
       //this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });

    }
}
