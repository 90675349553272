import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StyleManagerService } from "./style-manager";

@Injectable()
export class ThemeService {
  constructor(
    private http: HttpClient, private styleManager: StyleManagerService
  ) {}



  setTheme(themeToSet) {
    this.styleManager.setStyle(
        "theme",
        `assets/scss/${themeToSet}.css`
      );
  }
  removeTheme(themeToSet) {
    this.styleManager.removeStyle(
        "theme"
      );
    }
}
