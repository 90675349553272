import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InclusionService {

  private inclusionReport:any;

  constructor(
    private httpClient: HttpClient
  ) { }

  // getInclusionReport(id:any){
  //   if(!this.inclusionReport){
  //     this.inclusionReport = this.loadInclusionReport(id);
  //     return this.inclusionReport;
  //   }else{
  //     return this.inclusionReport;
  //   }

  // }

  getInclusionReport(id:any){
    return this.httpClient.get('assets/inclusionReport/'+id+'/report/'+id+'_inclusion_report.json');
  }

  getInclusionGraph(id:any, name:any){
    return this.httpClient.get('assets/inclusionReport/'+id+'/graphs/'+name+'.json');
  }
}
