import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';

import { Notification } from './topbar.model';

import { notificationItems } from './data';
import { ThemeService } from 'src/app/core/services/theme.service';

import { SharedService } from 'src/app/core/services/shardService';
import { SettingsService } from 'src/app/core/services/settings.service';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  status: boolean = false;

  isDarkMode:boolean = false;
  isActiveOna:boolean =false;
  isFranchise:boolean =false;

  notificationItems: Notification[];

  theme:string='light';
  ona:string='active';
  admin:string='non-franchise';

  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };

  user:any;

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(private router: Router, private authService: AuthenticationService, private readonly themeService: ThemeService, private sharedService: SharedService, private SettingsService: SettingsService) {}

  ngOnInit() {
    const theme = localStorage.getItem('theme');
    const ona = localStorage.getItem('ona');
    const admin = localStorage.getItem('admin');

    this.user = this.authService.currentUser();


    if(theme&&theme!=""){
      this.theme = theme;
    }

    if(ona&&ona!=""){
      this.ona = ona;
    }
    if(admin&&admin!=""){
      this.admin = admin;
    }


    localStorage.setItem('theme', this.theme);
    localStorage.setItem('ona', this.ona);
    localStorage.setItem('admin', this.admin);


    this.sharedService.updateOnaData(this.ona);
    this.sharedService.updateAdminData(this.admin);

   if(this.theme == 'dark'){
    this.themeService.setTheme('dark-theme');
    this.isDarkMode = true;
   }
   if(this.ona == 'active'){
    this.isActiveOna = true;
   }
   if(this.admin == 'franchise'){
    this.isFranchise = true;
   }


   // get the notifications
    this._fetchNotifications();
    this.openMobileMenu = false;

  }

  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
    this.status = !this.status;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['login'],{fragment: localStorage.getItem('loginUrl')});
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.notificationItems = notificationItems;
  }


  onChange($event){
   if(!this.isDarkMode){
    this.themeService.setTheme('dark-theme');
    localStorage.setItem('theme','dark');
   }
  else{
    this.themeService.removeTheme('dark-theme');
    localStorage.setItem('theme','light');
  }
}


onChangeOna($event){
  if(!this.isActiveOna){
    this.sharedService.updateOnaData('active');
   localStorage.setItem('ona','active');
  }
 else{
  this.sharedService.updateOnaData('passive');
   localStorage.setItem('ona','passive');
 }
}

onChangeAdmin($event){
  if(!this.isFranchise){
    this.sharedService.updateAdminData('franchise');
   localStorage.setItem('admin','franchise');
  }
 else{
  this.sharedService.updateAdminData('non-franchise');
   localStorage.setItem('admin','non-franchise');
 }

 this.admin = localStorage.getItem('admin');
 this.sharedService.admin.subscribe((data:any) => {
   this.admin=data

 })
}

setLanguage(lang:any){
  // console.log(lang);
  this.SettingsService.selectedLang.next(lang)
}

}
