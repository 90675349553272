import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  public ona: BehaviorSubject<string> = new BehaviorSubject('');
  public admin: BehaviorSubject<string> = new BehaviorSubject('');


  constructor() {}


  updateOnaData(data: any) {
    this.ona.next(data);
  }
  updateAdminData(data: any) {
    this.admin.next(data);
  }
}
