import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user:any;

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
        }
        return this.user;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
     login(email: string, password: string, study_hash: string) {
        return this.http.get<any>(`assets/mocks/users/users.json`)
            .pipe(map(studies => {
                // console.log(studies);
                studies.data.forEach(study => {
                  if(study.study_hash === study_hash){
                    study.users.forEach(user => {
                      if(user.email === email && user.password === password){
                        this.user = {
                          email : user.email,
                          study_hash: study_hash,
                          study_id: study.study_id,
                          report_type: study.report_type
                        };
                        this.cookieService.setCookie('currentUser', JSON.stringify(this.user), 1);
                        // localStorage.setItem('user', JSON.stringify(this.user));
                      }
                    });
                  }
                });
                return this.user;
            }));
    }


    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        this.user = null;
    }
}

